<template>
  <div class="d-flex flex-column">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mt-1">
        <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
          <b-card
            header="Primary"
            header-bg-variant="danger"
            header-text-variant="white"
            v-if="inactivePosData"
          >
            <template #header>
              <h6 class="mb-0">{{ $t("Inactive POS") }}</h6>
            </template>
            <b-card-text class="py-3 px-3">
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Please select the brands to Inactivate")
                    }}<span class="required ml-2">*</span> :
                  </h6>
                  <v-autocomplete
                    v-model="inactivePosData.brands"
                    :items="worksessionPosBrands"
                    item-text="name"
                    item-value="id"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Please select the reason to Inactivate")
                    }}<span class="required ml-2">*</span>
                    :
                  </h6>
                  <v-autocomplete
                    v-model="inactivePosData.reasonType"
                    :items="reasonTypes"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="requiredRulesArray"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row v-if="isCameraRequired">
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <div
                    class="inactiveUploadedPhoto text-center"
                    v-if="inactivePosData.inactivePhoto"
                  >
                    <img
                      :src="getFileImageUri(inactivePosData.inactivePhoto)"
                    />
                  </div>
                  <div
                    class="d-flex align-center justify-content-center webcameraWrapper"
                  >
                    <webCamera ref="webCameraInstance" />
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" offset-sm="0" md="10" offset-md="1">
                  <h6 class="my-2">
                    {{ $t("Explain the reason")
                    }}<span class="required ml-2">*</span> :
                  </h6>
                  <v-textarea
                    v-model="inactivePosData.gpvComments"
                    required
                    :rules="requiredRules"
                  ></v-textarea>
                </v-col>
              </v-row>
            </b-card-text>
            <template #footer>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="d-flex justify-content-center"
                >
                  <v-btn
                    color="success"
                    dark
                    class="mx-1 my-2"
                    :loading="isSaving"
                    @click="onSaveClick(inactivePosData)"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </b-card>

          <b-alert v-if="!inactivePosData" show variant="info">
            <b-icon
              icon="arrow-counterclockwise"
              animation="spin-reverse"
            ></b-icon>
            cargando datos...
          </b-alert>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import { logInfo, logError } from "@/utils";
import webCamera from "@/components/webCamera.vue";

export default {
  name: "inactivePOS",
  props: ["id", "worksessionPosId"],
  components: {
    webCamera
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", [
      "currentPosData",
      "currentWorksessionData",
      "worksessionPosBrands",
      "currentInactiveData"
    ]),
    isCameraRequired() {
      if (this.inactivePosData && this.inactivePosData.reasonType) {
        if (
          this.inactivePosData.reasonType === "CLOSINGBUSINESS" ||
          this.inactivePosData.reasonType === "DONTWORKCATEGORY"
        ) {
          return true;
        }
      }
      return false;
    }
  },

  data: function() {
    return {
      reasonTypes: [],
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      inactivePosData: null,
      isSaving: false
    };
  },
  methods: {
    getFileImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    async onSaveClick(inactivePosData) {
      if (this.$refs.form.validate()) {
        let body = inactivePosData;
        if (!body.date) {
          body.date = new Date().toISOString().slice(0, 10);
        }
        if (this.isCameraRequired) {
          const photoTaken = await this.$refs.webCameraInstance.getTakenPhotoFile();
          if (photoTaken) {
            let { data } = await ApiService.uploadImage(photoTaken);
            body.photoId = data.id;
          } else if (!body.inactivePhoto) {
            logError("¡Deberías tomar una foto!");
            return;
          }
        }
        this.isSaving = true;
        if (body.id) {
          await ApiService.put(`worksession/saveinactivedata/${body.id}`, body);
        } else {
          await ApiService.post(`worksession/saveinactivedata`, body);
        }
        this.isSaving = false;
        logInfo("Éxito");
        this.init();
      }
    },
    createSelectedModel(currentInactiveData) {
      let model = {
        id: _.get(currentInactiveData, "id"),
        userId: _.get(currentInactiveData, "userId"),
        routeId: _.get(currentInactiveData, "routeId"),
        posId: _.get(currentInactiveData, "posId"),
        reasonType: _.get(currentInactiveData, "reasonType"),
        gpvComments: _.get(currentInactiveData, "gpvComments"),
        brands: _.get(currentInactiveData, "brands"),
        photoId: _.get(currentInactiveData, "photoId"),
        inactivePhoto: _.get(currentInactiveData, "inactivePhoto"),
        date: _.get(currentInactiveData, "date")
      };
      if (model.brands && model.brands.length > 0) {
        model.brands = model.brands.map(el => el.id);
      } else {
        model.brands = null;
      }
      if (!model.userId) model.userId = this.user.id;
      if (!model.routeId) model.routeId = this.currentWorksessionData.routeId;
      if (!model.posId) model.posId = this.currentWorksessionData.posId;
      console.log("ALSKDJFSDF-", model);
      return model;
    },
    async init() {
      let { reasonTypes } = await ApiService.post(
        `worksession/getbasedata?isFlag=reasonTypes`
      );
      if (reasonTypes) {
        this.reasonTypes = [];
        for (const [key, value] of Object.entries(reasonTypes)) {
          let push_item = {
            label: value,
            value: key
          };
          this.reasonTypes.push(push_item);
        }
      }

      await this.$store.dispatch("myroute/getWorksessionPos", {
        worksessionPosId: this.worksessionPosId
      });
      console.log("currentWorksessionData - ", this.currentWorksessionData);
      console.log("worksessionPosBrands - ", this.worksessionPosBrands);

      await this.$store.dispatch("myroute/getCurrentInactiveData", {
        userId: this.user.id,
        routeId: this.currentWorksessionData.routeId,
        posId: this.currentWorksessionData.posId
      });
      console.log("currentInactiveData - ", this.currentInactiveData);
      this.inactivePosData = this.createSelectedModel(this.currentInactiveData);
    }
  },

  async mounted() {
    await this.init();
  }
};
</script>

<style>
span.required {
  color: #f00;
  font-weight: bold;
}
.inactiveUploadedPhoto img {
  max-width: 100%;
}
</style>
